import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/core/migration/check-circle';
import { Box } from '@atlaskit/primitives';
import { G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { FLAG_DISMISSED } from '../../common/constants';
import { type InteractedUIEventAction } from '../../common/types';
import { useACTAnalyticsEvents } from '../../common/utils';
import { useAdControlProvider } from '../../controllers';
import { messages } from '../messages';
import { overridePortalIndex } from '../utils';

const ACT_FLAG_WRAPPER = 'act-flag-wrapper';

const interactedUIEventAction: InteractedUIEventAction = {
	action: 'clicked',
	actionSubject: 'button',
	actionSubjectId: 'adControlsFlagClose',
};

const DismissalFlagGroup: React.FunctionComponent = () => {
	overridePortalIndex(ACT_FLAG_WRAPPER);
	const {
		state: { flags },
		createEventDispatcher,
	} = useAdControlProvider();
	const { createInteractedUIEvent } = useACTAnalyticsEvents();

	const handleOnDismissed = React.useCallback(
		(id: number | string) => {
			createEventDispatcher(id).dispatch(
				FLAG_DISMISSED,
				createInteractedUIEvent(interactedUIEventAction),
			);
		},
		[createEventDispatcher, createInteractedUIEvent],
	);

	return (
		<FlagGroup onDismissed={handleOnDismissed}>
			{Array.from(flags.entries()).map(
				([itemId, { actions = [], ...flagProps } = { actions: [] }], i) => (
					<Box key={i} id={ACT_FLAG_WRAPPER}>
						<AutoDismissFlag
							id={itemId}
							testId={`act-flag-${itemId}`}
							icon={
								<Tick
									label="Success"
									spacing="spacious"
									color={token('color.icon.success', G300)}
								/>
							}
							title={<FormattedMessage {...messages.notInterestedFlag} />}
							description={<FormattedMessage {...messages.dismissalMessage} />}
							{...flagProps}
							actions={actions.map((action) => ({
								...action,
								onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
									action.onClick?.(e, () => handleOnDismissed(itemId));
								},
							}))}
						/>
					</Box>
				),
			)}
		</FlagGroup>
	);
};

export default DismissalFlagGroup;
