import React from 'react';

import { IntlMessagesProvider } from '@atlaskit/intl-messages-provider';

import { fetchMessagesForLocale } from '../../common/utils/locale/fetch-messages-for-locale';
import messages from '../../i18n/en';

interface ActIntlProviderProps {
	children: React.ReactElement<any>;
}

const ActIntlProvider: React.FunctionComponent<ActIntlProviderProps> = ({ children }) => {
	return (
		<IntlMessagesProvider defaultMessages={messages} loaderFn={fetchMessagesForLocale}>
			{children}
		</IntlMessagesProvider>
	);
};

export default ActIntlProvider;
