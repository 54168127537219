import React, { Suspense } from 'react';

import { Box } from '@atlaskit/primitives';

import { type OnEventsCallback, type ReasonsModalProps } from '../../common/types';
import { AdControlContext } from '../../controllers/ad-control-provider/context';
import {
	adControlProviderReducer,
	initialAdControlProviderState,
} from '../../controllers/ad-control-provider/context/reducer';
import {
	type AdControlProviderAction,
	type AdControlProviderState,
} from '../../controllers/ad-control-provider/types';
import { ActIntlProvider } from '../act-intl-provider';
import { DismissalFlagGroup } from '../dismissal-flag-group';
import { ReasonsModal } from '../reasons-modal';

interface IAdControlProviderProps {
	/** The adControlDropdownMenu should be wrapped as a child under the provider
	 */
	children: React.ReactElement;
	/** Callback function to be invoked when events are dispatched
	 */
	onEvents?: OnEventsCallback;
	/** Should show the flag when "Not interested" is clicked, defaulted to true. Useful to not show if the component
	 * that owns the Ad Control Provider needs to provide its own flag and flag group when
	 * "Not interested" is clicked. It's imperative to still show a dismissal confirmation, per
	 * this page: https://hello.atlassian.net/wiki/spaces/~bsanders/pages/2552113748/Ad+controls+-+Patterns+Guidelines#2.-Confirmation-flags%2Fdialogues */
	shouldShowDismissFlag?: boolean;
}

const AdControlProvider = ({
	children,
	onEvents = () => {},
	shouldShowDismissFlag = true,
}: IAdControlProviderProps) => {
	const [state, dispatch] = React.useReducer<
		React.Reducer<AdControlProviderState, AdControlProviderAction>
	>(adControlProviderReducer, initialAdControlProviderState);

	const modalPropsRef = React.useRef<ReasonsModalProps>();

	const modalProps = modalPropsRef.current ?? { body: '', title: '' };

	return (
		<ActIntlProvider>
			<AdControlContext.Provider
				value={{
					state,
					modalPropsRef,
					dispatch,
					onEvents,
					shouldShowDismissFlag,
				}}
			>
				{children}
				<ReasonsModal itemId={state.modalItemId} {...modalProps} />
				<Suspense fallback={<Box />}>
					<DismissalFlagGroup />
				</Suspense>
			</AdControlContext.Provider>
		</ActIntlProvider>
	);
};

export default AdControlProvider;
