import { type ACTAnalyticsEvent, type ItemId, type OnEventsCallback } from '../../types';

/**
 * The class manages the dispatching of UI events.
 */
export default class ACTEventDispatcher {
	constructor(
		private readonly itemId: ItemId,
		private readonly handler: OnEventsCallback | undefined,
	) {}

	public dispatch(type: string, analyticsEvent?: ACTAnalyticsEvent): void {
		this._dispatch({ type, itemId: this.itemId, analyticsEvent });
	}

	public dispatchError(type: string, error: Error): void {
		this._dispatch({ type, itemId: this.itemId, error });
	}

	private _dispatch(event: Parameters<OnEventsCallback>[0]): void {
		if (this.handler) {
			this.handler(event);
		}
	}
}
