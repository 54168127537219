import { type AdControlProviderAction, type AdControlProviderState } from '../../types';

export const initialAdControlProviderState: AdControlProviderState = {
	flags: new Map(),
};

export const adControlProviderReducer: React.Reducer<
	AdControlProviderState,
	AdControlProviderAction
> = (state, action) => {
	switch (action.type) {
		case 'ADD_FLAG':
			return {
				...state,
				flags: new Map(state.flags).set(action.payload.itemId, action.payload.flagProps),
			};
		case 'REMOVE_FLAG': {
			// Clone the flags map and delete the flag
			const flags = new Map(state.flags);
			flags.delete(action.payload);
			return {
				...state,
				flags,
			};
		}
		case 'OPEN_MODAL':
			return {
				...state,
				modalItemId: action.payload,
			};
		case 'CLOSE_MODAL':
			return {
				...state,
				modalItemId: undefined,
			};
		default:
			return state;
	}
};
