/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//
export default {
  'act.action.dismissalMessage.common.text': 'You won’t see this here as often.',
  'act.action.notInterested.button.text': 'Not interested',
  'act.action.notInterestedFlag.flag.text': 'Got it, thanks for letting us know',
  'act.action.reasonsModalOkButton.common.text': 'OK',
  'act.action.showReasons.common.text': 'Why am I seeing this?',
};
