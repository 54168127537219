import React from 'react';

import { SCREEN_EVENT_TYPE, UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { type AdControlScreenNames } from '@atlassian/surface-analytics-specs';

import {
	type ACTInteractedUIEvent,
	type ACTScreenUIEvent,
	type InteractedUIEventAction,
} from '../../types';

interface UseACTAnalyticsEventHook {
	createInteractedUIEvent: (action: InteractedUIEventAction) => ACTInteractedUIEvent;
	createScreenUIEvent: (name: AdControlScreenNames) => ACTScreenUIEvent;
}

export default function useACTAnalyticsEvents(): UseACTAnalyticsEventHook {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const createInteractedUIEvent = React.useCallback(
		(action: InteractedUIEventAction): ACTInteractedUIEvent =>
			createAnalyticsEvent({
				eventType: UI_EVENT_TYPE,
				...action,
			}) as ACTInteractedUIEvent,
		[createAnalyticsEvent],
	);

	const createScreenUIEvent = React.useCallback(
		(name: AdControlScreenNames): ACTScreenUIEvent =>
			createAnalyticsEvent({
				eventType: SCREEN_EVENT_TYPE,
				name,
			}) as ACTScreenUIEvent,
		[createAnalyticsEvent],
	);

	return {
		createInteractedUIEvent,
		createScreenUIEvent,
	};
}
