import React from 'react';

import { FLAG_DISMISSED } from '../../../../common/constants';
import {
	type ACTEvent,
	type CustomizableFlagProps,
	type ItemId,
	type ReasonsModalProps,
} from '../../../../common/types';
import ACTEventDispatcher from '../../../../common/utils/act-event-dispatcher/actEventDispatcher';
import { useAdControlContext } from '../index';

export interface UseAdControlProviderHook {
	state: ReturnType<typeof useAdControlContext>['state'];
	openModal: (modalProps: ReasonsModalProps, modalItemId: ItemId) => void;
	closeModal: () => void;
	createEventDispatcher: (itemId: ItemId) => ACTEventDispatcher;
	addFlag: (itemId: ItemId, flagProps: CustomizableFlagProps | undefined) => void;
	shouldShowDismissFlag: boolean;
}

export function useAdControlProvider(): UseAdControlProviderHook {
	const { state, modalPropsRef, dispatch, onEvents, shouldShowDismissFlag } = useAdControlContext();

	/**
	 * Add a flag with flagProps to state for the item was dismissed
	 * {@link https://atlassian.design/components/flag/examples}
	 */
	const addFlag = React.useCallback(
		(itemId: ItemId, flagProps: CustomizableFlagProps | undefined) => {
			dispatch({ type: 'ADD_FLAG', payload: { itemId, flagProps } });
		},
		[dispatch],
	);

	/**
	 * Open a modal and set the modal props reference
	 */
	const openModal = React.useCallback(
		(modalProps: ReasonsModalProps, modalItemId: ItemId) => {
			modalPropsRef!.current = modalProps;
			dispatch({ type: 'OPEN_MODAL', payload: modalItemId });
		},
		[dispatch, modalPropsRef],
	);

	/**
	 * Close a modal and clear the modal props reference
	 */
	const closeModal = React.useCallback(() => {
		modalPropsRef!.current = undefined;
		dispatch({ type: 'CLOSE_MODAL' });
	}, [dispatch, modalPropsRef]);

	/**
	 * The internal event handler that is used by the ACTEventDispatcher
	 */
	const handleOnEvents = React.useCallback(
		(event: ACTEvent) => {
			// Handle the events that have side effects on the state.
			switch (event.type) {
				case FLAG_DISMISSED:
					dispatch({ type: 'REMOVE_FLAG', payload: event.itemId });
					break;
			}

			// Call the onEvents handler that is passed from the consumer.
			onEvents(event);
		},
		[dispatch, onEvents],
	);

	/**
	 * Create an event dispatcher for the given item id
	 */
	const createEventDispatcher = React.useCallback(
		(itemId: ItemId) => {
			return new ACTEventDispatcher(itemId, handleOnEvents);
		},
		[handleOnEvents],
	);

	return {
		state,
		openModal,
		closeModal,
		createEventDispatcher,
		addFlag,
		shouldShowDismissFlag,
	};
}
