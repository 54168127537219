export const DEFAULT_PORTAL_OVERRIDE_INDEX = 900;

/**
 * This is the temporary fix for z-index issue in confluence
 */
export function overridePortalIndex(
	elementId: string,
	index: number = DEFAULT_PORTAL_OVERRIDE_INDEX,
): void {
	setTimeout(() => {
		if (typeof document !== 'undefined') {
			const parentPortal = findParentBySelector(
				document.getElementById(elementId),
				'.atlaskit-portal',
			);

			if (parentPortal) {
				(parentPortal as HTMLElement).setAttribute('style', `z-index:${index};`);
			}
		}
	}, 0);
}

function collectionHas(all: NodeListOf<Element>, curr: any): boolean {
	for (let i = 0, len = all.length; i < len; i++) {
		if (all[i] === curr) {
			return true;
		}
	}
	return false;
}

export function findParentBySelector(
	elm: HTMLElement | null,
	selector: string,
): ParentNode | null | undefined {
	let all = document.querySelectorAll(selector);
	let cur = elm?.parentNode;
	while (cur && !collectionHas(all, cur)) {
		cur = cur.parentNode;
	}
	return cur;
}
