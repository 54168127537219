import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { DropdownItem } from '@atlaskit/dropdown-menu';

import { DISMISS_FAILED, DISMISS_STARTED, DISMISS_SUCCEEDED } from '../../common/constants';
import { type InteractedUIEventAction } from '../../common/types';
import { useACTAnalyticsEvents } from '../../common/utils';
import { useAdControlProvider } from '../../controllers';
import { messages } from '../messages';

import { type IDismissalButtonProps } from './types';

const interactedUIEventAction: InteractedUIEventAction = {
	action: 'clicked',
	actionSubject: 'button',
	actionSubjectId: 'notInterested',
};

const DismissalButton: React.FunctionComponent<IDismissalButtonProps> = ({
	itemId,
	onClick,
	children,
	flagProps,
	...props
}: IDismissalButtonProps) => {
	const { addFlag, createEventDispatcher, shouldShowDismissFlag } = useAdControlProvider();
	const { createInteractedUIEvent } = useACTAnalyticsEvents();

	const eventDispatcher = React.useMemo(
		() => createEventDispatcher(itemId),
		[createEventDispatcher, itemId],
	);

	const handleOnClick = React.useCallback(
		async (e: React.MouseEvent | React.KeyboardEvent) => {
			eventDispatcher.dispatch(DISMISS_STARTED, createInteractedUIEvent(interactedUIEventAction));

			shouldShowDismissFlag && addFlag(itemId, flagProps);

			try {
				await onClick?.(e, itemId);

				eventDispatcher.dispatch(DISMISS_SUCCEEDED);
			} catch (e: unknown) {
				eventDispatcher.dispatchError(DISMISS_FAILED, e as unknown as Error);
			}
		},
		[
			addFlag,
			createInteractedUIEvent,
			eventDispatcher,
			flagProps,
			itemId,
			onClick,
			shouldShowDismissFlag,
		],
	);

	return (
		<DropdownItem onClick={handleOnClick} {...props}>
			{children ?? <FormattedMessage {...messages.notInterested} />}
		</DropdownItem>
	);
};

export default DismissalButton;
