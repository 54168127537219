import React from 'react';

import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu';

import {
	AD_CONTROL_DROP_DOWN_MENU_CLOSED,
	AD_CONTROL_DROP_DOWN_MENU_OPENED,
} from '../../common/constants';
import { type InteractedUIEventAction } from '../../common/types';
import { useACTAnalyticsEvents } from '../../common/utils';
import { useAdControlProvider } from '../../controllers';

import { type AdControlDropdownMenuProps } from './types';

const interactedUIEventAction: InteractedUIEventAction = {
	action: 'clicked',
	actionSubject: 'button',
	actionSubjectId: 'adControlsDropdown',
};
const AdControlDropdownMenu: React.FC<AdControlDropdownMenuProps> = ({
	children,
	itemId,
	onOpenChange = () => {},
	...rest
}) => {
	const { createInteractedUIEvent } = useACTAnalyticsEvents();
	const { createEventDispatcher } = useAdControlProvider();

	const eventDispatcher = React.useMemo(
		() => createEventDispatcher(itemId),
		[createEventDispatcher, itemId],
	);

	return (
		<DropdownMenu
			{...rest}
			onOpenChange={(onOpenChangeArgs) => {
				eventDispatcher.dispatch(
					onOpenChangeArgs.isOpen
						? AD_CONTROL_DROP_DOWN_MENU_OPENED
						: AD_CONTROL_DROP_DOWN_MENU_CLOSED,
					createInteractedUIEvent(interactedUIEventAction),
				);
				onOpenChange(onOpenChangeArgs);
			}}
			shouldFitContainer={false}
		>
			<DropdownItemGroup>
				{typeof children === 'function' ? children({ itemId }) : children}
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export default AdControlDropdownMenu;
