import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { DropdownItem, type DropdownItemProps } from '@atlaskit/dropdown-menu';

import { REASONS_BUTTON_CLICKED } from '../../common/constants';
import { type InteractedUIEventAction } from '../../common/types';
import { useACTAnalyticsEvents } from '../../common/utils';
import { useAdControlProvider } from '../../controllers';
import { messages } from '../messages';

import { type IReasonsButtonProps } from './types';

const interactedUIEventAction: InteractedUIEventAction = {
	action: 'clicked',
	actionSubject: 'button',
	actionSubjectId: 'adControlsReasons',
};

const ReasonsButton = ({
	children,
	itemId,
	modalProps,
	onClick,
	...props
}: IReasonsButtonProps) => {
	const { createEventDispatcher, openModal } = useAdControlProvider();
	const { createInteractedUIEvent } = useACTAnalyticsEvents();

	const eventDispatcher = React.useMemo(
		() => createEventDispatcher(itemId),
		[createEventDispatcher, itemId],
	);

	const handleOnClick: NonNullable<DropdownItemProps['onClick']> = React.useCallback(
		(e) => {
			eventDispatcher.dispatch(
				REASONS_BUTTON_CLICKED,
				createInteractedUIEvent(interactedUIEventAction),
			);

			openModal(modalProps, itemId);

			onClick?.(e, itemId);
		},
		[createInteractedUIEvent, eventDispatcher, itemId, modalProps, onClick, openModal],
	);

	return (
		<DropdownItem onClick={handleOnClick} {...props}>
			{children ?? <FormattedMessage {...messages.showReasons} />}
		</DropdownItem>
	);
};
export default ReasonsButton;
