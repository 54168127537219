import React, { useEffect, useRef } from 'react';

import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Box } from '@atlaskit/primitives';
import { type AdControlScreenNames } from '@atlassian/surface-analytics-specs';

import { REASONS_MODAL_DISMISSED, REASONS_MODAL_SHOWN } from '../../common/constants';
import { type ReasonsModalProps } from '../../common/types';
import { useACTAnalyticsEvents } from '../../common/utils';
import type ACTEventDispatcher from '../../common/utils/act-event-dispatcher/actEventDispatcher';
import { useAdControlProvider } from '../../controllers';
import { messages } from '../messages';
import { DEFAULT_PORTAL_OVERRIDE_INDEX, overridePortalIndex } from '../utils/use-portal-index';

const adControlScreenName: AdControlScreenNames = 'adControlsReasonsModal';

const ACT_MODAL_CONTENT = 'ACT_MODAL_CONTENT';

const ReasonsModal: React.FC<ReasonsModalProps> = ({
	body,
	itemId,
	title,
	zIndexOverride = DEFAULT_PORTAL_OVERRIDE_INDEX,
	...props
}) => {
	const { closeModal, createEventDispatcher } = useAdControlProvider();
	const { createScreenUIEvent } = useACTAnalyticsEvents();
	const isMounted = useRef(false);
	const eventDispatcherRef = useRef<ACTEventDispatcher>();

	eventDispatcherRef.current = React.useMemo(
		() => (itemId !== undefined ? createEventDispatcher(itemId) : eventDispatcherRef.current),
		[createEventDispatcher, itemId],
	);

	const isOpen = itemId !== undefined;

	useEffect(() => {
		const eventDispatcher = eventDispatcherRef.current;

		if (!eventDispatcher) {
			return;
		}

		if (isOpen) {
			eventDispatcher.dispatch(REASONS_MODAL_SHOWN, createScreenUIEvent(adControlScreenName));
		} else if (isMounted.current && !isOpen) {
			eventDispatcher.dispatch(REASONS_MODAL_DISMISSED, createScreenUIEvent(adControlScreenName));
		}
	}, [createScreenUIEvent, isOpen]);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	// DO NOT MODIFY CODE BLOCK - temp fix for z-index issue in confluence
	if (isOpen && zIndexOverride) {
		overridePortalIndex(ACT_MODAL_CONTENT, zIndexOverride);
	}
	// DO NOT MODIFY CODE BLOCK - temp fix for z-index issue in confluence

	return (
		<ModalTransition>
			{isOpen && (
				<Modal shouldCloseOnEscapePress onClose={closeModal} {...props}>
					<Box id={ACT_MODAL_CONTENT}>
						{title !== null && (
							<ModalHeader>
								<ModalTitle>{title ?? <FormattedMessage {...messages.showReasons} />}</ModalTitle>
							</ModalHeader>
						)}
						<ModalBody>{body}</ModalBody>
						<ModalFooter>
							<Button appearance="primary" onClick={closeModal} autoFocus>
								<FormattedMessage {...messages.reasonsModalOkButton} />
							</Button>
						</ModalFooter>
					</Box>
				</Modal>
			)}
		</ModalTransition>
	);
};
export default ReasonsModal;
