import React from 'react';

import type { AdControlContextValue } from '../types';

import { initialAdControlProviderState } from './reducer';
export const AdControlContext = React.createContext<AdControlContextValue>({
	state: { ...initialAdControlProviderState },
	modalPropsRef: undefined,
	dispatch: () => {},
	onEvents: () => {},
	shouldShowDismissFlag: true,
});
