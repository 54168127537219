import { type I18NMessages } from '@atlaskit/intl-messages-provider';

import { locales } from '../../../i18n';
import enMessages from '../../../i18n/en';

const localeMap: Record<string, string> = {
	pt: 'pt-BR',
	'pt-PT': 'pt-BR',
	'en-AU': 'en-GB',
};

export const fetchMessagesForLocale = async (locale: string): Promise<I18NMessages | undefined> => {
	try {
		const targetLocale = (localeMap[locale] ?? locale ?? '').replace('-', '_');

		if (targetLocale in locales) {
			const messages = await locales[targetLocale]();
			return messages.default;
		}
	} catch (e) {
		// ignore
	}

	try {
		const parentLocale = locale.split(/[-_]/)[0];
		if (parentLocale in locales) {
			const messages = await locales[parentLocale]();
			return messages.default;
		}
	} catch (e) {
		// ignore
	}

	/**
	 * English bundled by default as this is the majority of users
	 */
	return enMessages;
};
