import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	notInterested: {
		id: 'act.action.notInterested.button.text',
		defaultMessage: 'Not interested',
		description: 'The text of a button to indicate the users can dismiss the current component',
	},
	notInterestedFlag: {
		id: 'act.action.notInterestedFlag.flag.text',
		defaultMessage: 'Got it, thanks for letting us know',
		description: 'A message that informs the users the dismissal action is taken',
	},
	showReasons: {
		id: 'act.action.showReasons.common.text',
		defaultMessage: 'Why am I seeing this?',
		description:
			"The text of a button to indicate the users can click and see the reasons for a component's appearance",
	},
	dismissalMessage: {
		id: 'act.action.dismissalMessage.common.text',
		defaultMessage: 'You won’t see this here as often.',
		description: 'A message that informs the users the dismissal action is taken',
	},
	reasonsModalOkButton: {
		id: 'act.action.reasonsModalOkButton.common.text',
		defaultMessage: 'OK',
		description: 'The text of a button to indicate the users can dismiss the current modal',
	},
});
