// ACT Event types
// AdControlDropdownMenu
export const AD_CONTROL_DROP_DOWN_MENU_OPENED: string = 'AD_CONTROL_DROP_DOWN_MENU_OPENED';
export const AD_CONTROL_DROP_DOWN_MENU_CLOSED: string = 'AD_CONTROL_DROP_DOWN_MENU_CLOSED';

// Dismiss button
export const DISMISS_STARTED: string = 'DISMISS_STARTED';
export const DISMISS_SUCCEEDED: string = 'DISMISS_SUCCEEDED';
export const DISMISS_FAILED: string = 'DISMISS_FAILED';

// Reasons modal
export const REASONS_BUTTON_CLICKED: string = 'REASONS_BUTTON_CLICKED';
export const REASONS_MODAL_SHOWN: string = 'REASONS_MODAL_SHOWN';
export const REASONS_MODAL_DISMISSED: string = 'REASONS_MODAL_DISMISSED';

// Flags
export const FLAG_DISMISSED: string = 'FLAG_DISMISSED';
